import * as msal from 'msal'
import {getDjangoToken} from "./api";

export let token: string | undefined = undefined

// Config object to be passed to msal on creation
const msalConfig: msal.Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage", // cacheLocation is also related to the lifetime of cache
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const msalAgent = new msal.UserAgentApplication(msalConfig);

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: ["User.Read"],
  claims: {"access_token": {"polids": {"essential": true, "Values": ["<GUID>"]}}}
};

export const refreshTokenRequest = {
  scopes: [process.env.REACT_APP_CLIENT_ID ?? ""],
  claims: {"access_token": {"polids": {"essential": true, "Values": ["<GUID>"]}}}
};

// callback function called after redirect
export function authCallback(error: Error | undefined, response: msal.AuthResponse | undefined) {
  //handle redirect response
  token = response?.accessToken
  console.log("callback, response token: " + token)

  msalAgent.acquireTokenSilent(tokenRequest).then(function (accessTokenResponse) {
    // Acquire token silent success
    // Call API with token
    token = accessTokenResponse.accessToken;
    console.log("callback, acquired token: " + token)
  }).catch(function (error) {
    //Acquire token silent failure, and send an interactive request
    console.log("silent call did not work in callback");
    console.log(error);
  });
}

export function login() {
  msalAgent.loginRedirect(tokenRequest);
}

// check if user is authorized
export function isAuthorized(callback: (result: boolean) => void) {
  msalAgent.acquireTokenSilent(tokenRequest).then(function (accessTokenResponse) {
    // Acquire token silent success
    // Call API with token
    token = accessTokenResponse.accessToken;
    console.log("callback, acquired token: " + token)
    console.log("return true")
    callback(true)
  }).catch(function (error) {
    //Acquire token silent failure, and send an interactive request
    console.log("silent call did not work in callback");
    console.log(error);
    console.log("return false")
    callback(false)
  });
}

// Used when app mounted to check if user is authorized and if not to redirect to ms login page
export function authInit(callback: (result: boolean, token: string | undefined) => void) {
  msalAgent.acquireTokenSilent(tokenRequest).then(function (accessTokenResponse) {
    // Acquire token silent success
    // Call API with token
    //console.log(accessTokenResponse)
    token = accessTokenResponse.accessToken;
    //console.log(token)
    callback(true, token)
  }).catch(function (error) {
    //Acquire token silent failure, and send an interactive request
    console.log("silent didt work");
    console.log(error);
    if (error instanceof msal.InteractionRequiredAuthError) {
      msalAgent.acquireTokenRedirect(tokenRequest);
    } else {
      msalAgent.loginRedirect(tokenRequest);
    }
  });
}

export function refreshToken(updateApiToken: typeof getDjangoToken, callback: (authToken: string) => void) {
  //msalAgent.acquireTokenSilent(refreshTokenRequest).then(function (accessTokenResponse) {
  msalAgent.acquireTokenSilent(tokenRequest).then(function (accessTokenResponse) {
    console.log("refresh worked");
    //callback(accessTokenResponse.accessToken)
    updateApiToken(accessTokenResponse.accessToken, callback)
  }).catch(function (error) {
    //Acquire token silent failure, and send an interactive request
    console.log("silent refresh didt work");
    console.error(error);
  });
}




