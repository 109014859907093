import React from "react"
import '../styles/Calendar.css';
import DayPicker, {DayModifiers} from 'react-day-picker';
import {getInitDay} from "../utils/utils";

interface CalendarProps {
  closeCallback: () => void
  changeDateCallback: (newDate: Date | undefined) => void
}

interface CalendarState {
  selectedDay: Date | undefined
}

export class Calendar extends React.Component<CalendarProps, CalendarState> {
  constructor(props: CalendarProps) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDay: undefined
    }
  }

  handleDayClick = (day: Date, modifiers: DayModifiers) => {
    console.log("selected: " + modifiers + ", day: " + day)
    if (modifiers.disabled) {
      return
    }
    this.setState({
      selectedDay: modifiers.selected ? undefined : day,
    });
  };

  handleChangeDate = () => {
    let {changeDateCallback} = this.props;
    let {selectedDay} = this.state;
    changeDateCallback(selectedDay)
  };

  render() {
    let {closeCallback} = this.props;
    let {selectedDay} = this.state;
    return (
      <div className="calendar-container">
        <DayPicker
          selectedDays={selectedDay}
          onDayClick={this.handleDayClick}
          disabledDays={[{after: getInitDay()}]}
          month={getInitDay()}
        />
        <div className="calendar-button__container">
          <button className="calendar-button" onClick={closeCallback}>CANCEL</button>
          <button className="calendar-button" onClick={this.handleChangeDate}>OK</button>
        </div>
      </div>
    )
  }
}
