import React from 'react';
import './App.css';
import {Dashboard} from "./components/Dashboard";
import {Login} from "./components/Login"
import {authInit, msalAgent, refreshToken, tokenRequest} from "./utils/authConfig";
import * as msal from "msal";
import {AddToHomescreenPopup} from "./components/AddToHomescreenPopup";
import {getDjangoToken} from "./utils/api";

interface AppProps {

}

interface AppState {
  authToken: string | undefined
  isAuthorized: boolean
  showPopup: boolean
}

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      authToken: undefined,
      isAuthorized: false,
      showPopup: false
    }

    msalAgent.handleRedirectCallback(this.authCallback);
    this.updateAuthStatus = this.updateAuthStatus.bind(this);
  }

  componentDidMount(): void {

    //isAuthorized(this.updateAuthStatus)
    authInit(this.updateAuthStatus)

    if (this.isIos()) {
      this.setState({ showPopup: true });
    }
  }

  updateAuthStatus = (result: boolean, token: string | undefined) => {
    this.setState({isAuthorized: result, authToken: token})
  }

  //handle redirect response
  authCallback(error: Error | undefined, response: msal.AuthResponse | undefined) {
    const updateAuthStatus = this.updateAuthStatus

    msalAgent.acquireTokenSilent(tokenRequest).then(function (accessTokenResponse) {
      // Acquire token silent success
      // Call API with token
      console.log(accessTokenResponse)
      const token = accessTokenResponse.accessToken;
      console.log("callback, acquired silent token: " + token)
      updateAuthStatus(true, token)
    }).catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      console.log("silent call did not work in callback");
      console.log(error);
    });
  }

  refreshMsalToken = (updateApiToken: typeof getDjangoToken, callback: (authToken: string) => void) => {
    refreshToken(updateApiToken, callback)
  }

  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    console.log(userAgent)
    return /iphone|ipad|ipod/.test( userAgent );
  }

  closePopup = () => {
    this.setState({showPopup: false})
  }

  render() {
    let {isAuthorized, showPopup, authToken} = this.state;
    return (
      <div className="App" id="App">
        {isAuthorized ? <Dashboard msalToken={authToken} refreshMsalToken={this.refreshMsalToken}/> : <Login/>}
        { showPopup ?  <AddToHomescreenPopup closePopup={this.closePopup}/> : null}
      </div>
    );
  }
}

export default App;
