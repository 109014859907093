import React from "react"
import {NavIcon} from "./SVGs/NavIcon"
import {Link} from 'react-scroll';
import '../styles/NavigationBar.css';

interface NavigationProps {
  landscape: boolean
  services: Service[]
}

type Service = {
  label: string,
  status: string
}

export function NavigationBar(props: NavigationProps) {
  let {services, landscape} = props

  return (
    <div className={landscape ? "nav-bar--landscape" : "nav-bar"}>
      {services.map((service) => {
        return (
          <Link key={service.label.toLowerCase()} activeClass="active" to={service.label} spy={true} smooth={true}
                duration={300} offset={-165 + (landscape ? 120 : 0)}>
            <div className="nav-bar__service-icon">
              <NavIcon status={service.status}/>
              <div className={"nav-bar__service-icon--" + service.status.toLowerCase()}> {service.label} </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}
