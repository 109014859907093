import React from "react"
import '../styles/AddToHomescreenPopup.css';

const square = require('../assets/square.and.arrow.up.svg');
const plus = require('../assets/plus.app.svg');

interface AddToHomescreenPopupProps {
  closePopup: () => void
}

export function AddToHomescreenPopup(props: AddToHomescreenPopupProps) {

  return (
    <div className="pop-up__container" onClick={props.closePopup}>
      <div className="pop-up__close-button"> <button className="pop-up__close-button" >x</button></div>
      <div className="pop-up__text" >
        To install this app:
        <p>
          Press &nbsp; <img alt='sq' className="pop-up__icon" src={String(square)}/>
          &nbsp; &rarr; &nbsp; Add to Home Screen &nbsp; <img alt='pl' className="pop-up__icon" src={String(plus)}/>
        </p>
      </div>
    </div>
  )
}
