import React from "react"
import {Link} from 'react-scroll';
import '../styles/Footer.css';
import {KeySymbols} from "./SVGs/KeySymbols";
import {Element} from "react-scroll/modules";
const logo = require('../assets/LogoTSCNET.svg');
const up = require('../assets/up.svg');

export function Footer() {

  return (
    <div className="footer">
      <Element name="footer">
      <KeySymbols/>
      <div className="footer__row">
        <div>
          <img alt='logo' style={{width: "100%"}} src={String(logo)}/>
        </div>
        <div>
          <Link to={"map"} activeClass="active" spy={true} smooth={true} duration={250} offset={-200}>
            <img alt='up' className="footer__row__go-up-button" src={String(up)}/>
          </Link>
        </div>
      </div>
      </Element>
    </div>
  )
}
