import {ServiceReport, ServicesResponse} from "./api";

export function getFormattedDate(date: Date) {
  let parsedDate = parseDate(date);
  return parsedDate.dd + "." + parsedDate.mm + "." + parsedDate.yyyy
}

export function getDateKey(date: Date) {
  let parsedDate = parseDate(date);
  return parsedDate.yyyy + "-" +  parsedDate.mm + "-" +  parsedDate.dd
}

export function getDateStringForAPICall(date: Date) {
  let parsedDate = parseDate(date);
  return parsedDate.yyyy + parsedDate.mm + parsedDate.dd
}

function parseDate(date: Date) : {dd: string, mm: string, yyyy: string} {
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth()+1).padStart(2, '0'); //January is 0!
  let yyyy = date.getFullYear();
  return {dd:dd, mm:mm, yyyy: yyyy.toString()}
}

export function getColor(color: string) {
  switch (color) {
    case "red":
      return getComputedStyle(document.body).getPropertyValue('--redColor');
    case "purple":
      return getComputedStyle(document.body).getPropertyValue('--purpleColor');
    case "blue":
      return getComputedStyle(document.body).getPropertyValue('--blueColor');
    default:
      return getComputedStyle(document.body).getPropertyValue('--primaryColorLight');
  }
}

export function getMapColors(color: string) {
  switch (color) {
    case "red":
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapRedLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapRedDark')
      };
    case "purple":
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapPurpleLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapPurpleDark')
      };
    case "blue":
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapBlueLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapBlueDark')
      };
    default:
      return {
        top: getComputedStyle(document.body).getPropertyValue('--mapGrayLight'),
        middle: getComputedStyle(document.body).getPropertyValue('--mapGrayDark')
      };
  }
}

export function formatTimeFrame(timeframe: string): string {
  switch (timeframe) {
    case "dayAhead": return "Day Ahead";
    case "weekAhead": return "Week ahead";
    case "yearAhead": return "Year ahead";
    case "intraDay": return "Intra Day";
    default: return "";
  }
}

export function getServiceFullName(label: string) {
  switch (label.toLowerCase()) {
    case "csa":
      return "Coordinated security analysis";
    case "cc cwe":
      return "Coordinated capacity calculation";
    case "cc ibwt":
      return "Coordinated capacity calculation";
    case "opc":
    case "opc/opi":
      return "Outage planning coordination";
    case "sta":
      return "Short term adequacy";
    default:
      return ""
  }
}

export function getInitDay() {
  let initDate = new Date();
  initDate.setDate(initDate.getDate() - 1);
  initDate.setHours(initDate.getHours() - 6);
  return initDate
}

export const initNavBar = [
  {label: "csa", status: ""},
  {label: "cc cwe", status: ""},
  {label: "cc ibwt", status: ""},
  {label: "opc/opi", status: ""},
  {label: "sta", status: ""}
]

export const emptyServicesResponse: ServicesResponse = {};

export const sortServices = (a: ServiceReport, b: ServiceReport) => {
  const serviceLables = initNavBar.map(el => el.label);
  const aLabelIndex = serviceLables.indexOf(a.label.toLowerCase());
  const bLabelIndex = serviceLables.indexOf(b.label.toLowerCase());
  return aLabelIndex - bLabelIndex
};

// const mockServiceList = [
//   {
//     label: "CSA",
//     date: new Date(),
//     reports: [
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "purple",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       }
//
//     ]
//   },
//   {
//     label: "CC CWE",
//     date: new Date(),
//     reports: [
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "purple",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       }
//
//     ]
//   },
//   {
//     label: "CC IBWT",
//     date: new Date(),
//     reports: [
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       }
//
//     ]
//   },
//   {
//     label: "OPC",
//     date: new Date(),
//     reports: [
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       }
//     ]
//   },
//   {
//     label: "STA",
//     date: new Date(),
//     reports: [
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "red"
//       },
//       {
//         inner: "blue",
//         outer: "blue"
//       },
//       {
//         inner: "blue",
//         outer: "purple"
//       }
//     ]
//   }
// ]