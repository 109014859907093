import React from "react"

export function NavIcon(props: { status: string }) {
  switch (props.status) {
    case "red":
      return <RedIcon/>
    case "purple":
      return <PurpleIcon/>
    case "blue":
      return <BlueIcon/>
    case "gray":
      return <GrayIcon/>
    default:
      return <NoDataIcon/>
  }
}

function RedIcon() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="mobile-case-3-1-copy" transform="translate(-20.000000, -39.000000)">
          <g id="Header---Preview">
            <g id="Icon-big-Business-Impact" transform="translate(20.000000, 39.000000)">
              <circle id="Oval" fill="#E36669" cx="23.9760003" cy="23.9760003" r="23.9760003"/>
              <rect id="Rectangle" fill="#242E42" x="15" y="21" width="18" height="6"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

function BlueIcon() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="mobile-case-2-1-copy" transform="translate(-91.000000, -39.000000)">
          <g id="Header---Preview">
            <g id="Icon-big-CHECK" transform="translate(91.000000, 39.000000)">
              <g id="Preview-1">
                <circle id="Oval" fill="#6AC0FF" cx="24" cy="24" r="24"/>
                <polyline id="Fill-1" fill="#242E42"
                          points="31.5945833 15 21.25 26.567 16.4045 21.556 13 25.272 21.25 34 35 18.715 31.5945833 15"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

function PurpleIcon() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-big-KPI-Breach">
          <circle id="Oval" fill="#B254CD" cx="23.9760003" cy="23.9760003" r="23.9760003"/>
          <circle id="Oval" stroke="#232F46" strokeWidth="2.9305" cx="24" cy="24" r="12"/>
        </g>
      </g>
    </svg>
  )
}

function GrayIcon() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="mobile-case-3-1-copy" transform="translate(-20.000000, -39.000000)">
          <g id="Header---Preview">
            <g id="Icon-no-data" transform="translate(20.000000, 39.000000)">
              <circle id="Oval" fill="#8191AF" cx="23.9760003" cy="23.9760003" r="23.9760003"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

function NoDataIcon() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1">
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="mobile-case-3-1-copy" transform="translate(-20.000000, -39.000000)">
          <g id="Header---Preview">
            <g id="Icon-no-data" transform="translate(20.000000, 39.000000)">
              <circle id="Oval" fill="#415271" cx="23.9760003" cy="23.9760003" r="23.9760003"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
