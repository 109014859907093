import React from "react"
import {Element} from 'react-scroll';
import {getFormattedDate} from "../utils/utils"
import '../styles/Header.css';
import {Link} from "react-scroll/modules";

interface HeaderProps {
  processDate: Date
  callback: () => void
}

export function Header(props: HeaderProps) {

  const calendarIcon = require('../assets/calendar.svg');
  const infoIcon = require('../assets/info.circle.svg');
  return (
    <Element name="header">
      <div className="header" >
        <div className="header__text">
          <Link to="footer" spy={true} smooth={true}
                duration={300}>
          <img alt='calendar' className="header__icon--info" src={String(infoIcon)}/>
          &nbsp; TSCNET REPORT
          </Link>
        </div>
        <div className="header__date-container" onClick={props.callback}>
          <div className="header__date-container__date"> PROCESS DATE: {getFormattedDate(props.processDate)}</div>
          <img alt='calendar' className="header__date-container__icon" src={String(calendarIcon)}/>
        </div>
      </div>
    </Element>
  )
}
