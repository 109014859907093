import React from "react"
import "../../styles/Footer.css"
import {getFormattedDate} from "../../utils/utils";

export function KeySymbols() {
  return (
    <div className="footer__key-symbols">
      <div className="footer__key-symbols--phone" >
        <KeySymbolsMobile />
      </div>
      <div className="footer__key-symbols--tablet">
        <KeySymbolsTablet />
      </div>
    </div>
  )
}

function KeySymbolsMobile() {
  return (
    <svg  viewBox="0 0 335 302" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="grid-copy" transform="translate(-20.000000, -2101.000000)">
          <g id="TSCNET-updated-key" transform="translate(20.000000, 2101.000000)">
            <rect id="Rectangle" fillOpacity="0.35" fill="#415271" x="0" y="0" width="335" height="302" rx="8"/>
            <text id="Report-created-on-02" fontSize="13"  letterSpacing="0.722222222"
                  fill="#8191AF">
              <tspan x="68" y="275">Report created on {getFormattedDate(new Date())}</tspan>
            </text>
            <text id="KPIs-met" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="53" y="146">KPIs met</tspan>
            </text>
            <g id="Group-3" transform="translate(20.000000, 68.000000)">
              <g id="Group-2" strokeWidth="2">
                <circle id="Oval" stroke="#6AC0FF" cx="10.5" cy="10.5" r="10.5"/>
                <circle id="Oval" stroke="#4A5E7E" cx="10.5" cy="10.5" r="6.5"/>
              </g>
              <text id="day-ahead" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
                <tspan x="33" y="17">day ahead</tspan>
              </text>
            </g>
            <g id="Group" transform="translate(20.000000, 36.000000)" strokeWidth="2">
              <circle id="Oval" stroke="#4A5E7E" cx="10.5" cy="10.5" r="10.5"/>
              <circle id="Oval" stroke="#6AC0FF" cx="10.5" cy="10.5" r="6.5"/>
            </g>
            <text id="-1d" fontFamily="Avenir-Heavy, Avenir" fontSize="12" fontWeight="600"
                  letterSpacing="0.808920085" fill="#689FDE">
              <tspan x="19" y="114">-1D</tspan>
            </text>
            <text id="1-day-in-the-past" fontSize="13"  letterSpacing="0.722222222"
                  fill="#8191AF">
              <tspan x="53" y="115">1 day in the past</tspan>
            </text>
            <text id="intra-day" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="53" y="54">intra day</tspan>
            </text>
            <text id="day-ahead" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="211" y="85">day ahead</tspan>
            </text>
            <text id="weekly" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="211" y="116">weekly</tspan>
            </text>
            <text id="intra-day" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="211" y="54">intra day</tspan>
            </text>
            <text id="KPIs-breached" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="53" y="176">KPIs breached</tspan>
            </text>
            <text id="Business-impact" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="53" y="207">Business impact</tspan>
            </text>
            <circle id="Oval" fill="#6AC0FF" cx="29" cy="137" r="9"/>
            <circle id="Oval" fill="#B254CD" cx="29" cy="168" r="9"/>
            <text id="KPIs-met" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="211" y="146">KPIs met</tspan>
            </text>
            <text id="KPIs-breached" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="211" y="177">KPIs breached</tspan>
            </text>
            <text id="Business-impact" fontSize="13"  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="211" y="207">Business impact</tspan>
            </text>
            <g id="Group-4" transform="translate(180.000000, 193.000000)">
              <circle id="Oval" fill="#E36669" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
              <rect id="Rectangle" fill="#242E42" x="5" y="8" width="7" height="2"/>
            </g>
            <g id="Group-4" transform="translate(20.000000, 193.000000)">
              <circle id="Oval" fill="#E36669" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
              <rect id="Rectangle" fill="#242E42" x="5" y="8" width="7" height="2"/>
            </g>
            <text id="No-data-available" fontSize="13"  letterSpacing="0.722222222"
                  fill="#8191AF">
              <tspan x="53" y="238">No data available</tspan>
            </text>
            <g id="Group-4" transform="translate(20.000000, 224.000000)" fill="#8191AF">
              <circle id="Oval" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
            </g>
            <text id="No-data-available" fontSize="13"  letterSpacing="0.722222222"
                  fill="#8191AF">
              <tspan x="213" y="238">No data available</tspan>
            </text>
            <g id="Group-4" transform="translate(180.000000, 224.000000)" fill="#8191AF">
              <circle id="Oval" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
            </g>
            <circle id="Oval" fill="#6AC0FF" cx="189" cy="140" r="9"/>
            <polyline id="Fill-1" fill="#303D56"
                      points="193.1425 135 187.5 141.087895 184.857 138.450526 183 140.406316 187.5 145 195 136.955263 193.1425 135"/>
            <circle id="Oval" fill="#B254CD" cx="189" cy="171" r="9"/>
            <text id="Circle" fontSize="13"  letterSpacing="1.08333337" fill="#FFFFFF">
              <tspan x="20" y="24">Circle</tspan>
            </text>
            <text id="Details" fontSize="13"  letterSpacing="1.08333337" fill="#FFFFFF">
              <tspan x="179" y="25">Details</tspan>
            </text>
            <circle id="Oval" fill="#415271" cx="189" cy="49" r="11"/>
            <path
              d="M189,43 C185.686292,43 183,45.6862915 183,49 C183,52.3137085 185.686292,55 189,55 C192.313708,55 195,52.3137085 195,49 C195,45.6862915 192.313708,43 189,43 Z M189,54.1818182 C186.138161,54.1818182 183.818182,51.8618392 183.818182,49 C183.818182,46.1381608 186.138161,43.8181818 189,43.8181818 C191.861839,43.8181818 194.181818,46.1381608 194.181818,49 C194.181818,51.8618392 191.861839,54.1818182 189,54.1818182 Z"
              id="Shape" fill="#6AC0FF"/>
            <path
              d="M191.666667,48.2258065 L189.666667,48.2258065 L189.666667,45.3870968 C189.666667,45.1733091 189.517428,45 189.333333,45 C189.149238,45 189,45.1733091 189,45.3870968 L189,49 L191.666667,49 C191.850762,49 192,48.8266909 192,48.6129032 C192,48.3991156 191.850762,48.2258065 191.666667,48.2258065 L191.666667,48.2258065 Z"
              id="Shape" fill="#6AC0FF"/>
            <g id="Time-2"
               transform="translate(189.000000, 110.000000) rotate(-270.000000) translate(-189.000000, -110.000000) translate(178.000000, 99.000000)">
              <circle id="Oval" fill="#415271" cx="11" cy="11" r="11"/>
              <path
                d="M4.83501456,10.3817394 C4.83501456,13.81479 7.61805197,16.5978275 11.0511026,16.5978275 C14.4841532,16.5978275 17.2671906,13.81479 17.2671906,10.3817394 C17.2671906,6.94868878 14.4841532,4.16565136 11.0511026,4.16565136 C7.61805197,4.16565136 4.83501456,6.94868878 4.83501456,10.3817394 Z M11.0511026,15.7501791 C8.08619524,15.7501791 5.68266293,13.3466468 5.68266293,10.3817394 C5.68266293,7.41683204 8.08619524,5.01329973 11.0511026,5.01329973 C14.01601,5.01329973 16.4195423,7.41683204 16.4195423,10.3817394 C16.4195423,11.8055381 15.8539404,13.1710218 14.8471627,14.1777995 C13.840385,15.1845772 12.4749013,15.7501791 11.0511026,15.7501791 Z"
                id="Shape" fill="#6AC0FF"/>
              <path
                d="M10.5312116,7.80488836 C10.3642194,7.64928337 10.1039913,7.65387479 9.94259226,7.81527386 C9.78119318,7.97667294 9.77660176,8.23690097 9.93220675,8.40389321 L11.8309391,10.3026256 L9.93220675,12.2013579 C9.77660176,12.3683502 9.78119318,12.6285782 9.94259226,12.7899773 C10.1039913,12.9513763 10.3642194,12.9559677 10.5312116,12.8003628 L13.0289488,10.3026256 L12.429944,9.70362071 L10.5312116,7.80488836 Z"
                id="Shape" fill="#6AC0FF"/>
            </g>
            <g id="Time-2" transform="translate(178.000000, 68.000000)">
              <circle id="Oval" fill="#415271" cx="11" cy="11" r="11"/>
              <path
                d="M11.0511026,4.16565136 C7.61805197,4.16565136 4.83501456,6.94868878 4.83501456,10.3817394 C4.83501456,13.81479 7.61805197,16.5978275 11.0511026,16.5978275 C14.4841532,16.5978275 17.2671906,13.81479 17.2671906,10.3817394 C17.2671906,6.94868878 14.4841532,4.16565136 11.0511026,4.16565136 L11.0511026,4.16565136 Z M11.0511026,15.7501791 C8.08619524,15.7501791 5.68266293,13.3466468 5.68266293,10.3817394 C5.68266293,7.41683204 8.08619524,5.01329973 11.0511026,5.01329973 C14.01601,5.01329973 16.4195423,7.41683204 16.4195423,10.3817394 C16.4195423,11.8055381 15.8539404,13.1710218 14.8471627,14.1777995 C13.840385,15.1845772 12.4749013,15.7501791 11.0511026,15.7501791 Z"
                id="Shape" fill="#6AC0FF"/>
              <path
                d="M10.5312116,7.80488836 C10.3642194,7.64928337 10.1039913,7.65387479 9.94259226,7.81527386 C9.78119318,7.97667294 9.77660176,8.23690097 9.93220675,8.40389321 L11.8309391,10.3026256 L9.93220675,12.2013579 C9.77660176,12.3683502 9.78119318,12.6285782 9.94259226,12.7899773 C10.1039913,12.9513763 10.3642194,12.9559677 10.5312116,12.8003628 L13.0289488,10.3026256 L12.429944,9.70362071 L10.5312116,7.80488836 Z"
                id="Shape" fill="#73ACFF"/>
            </g>
            <circle id="Oval" stroke="#232F46" strokeWidth="1.93050018" cx="189" cy="171" r="5"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

function KeySymbolsTablet() {
  return (
    <svg viewBox="0 0 737 259" version="1.1" xmlns="http://www.w3.org/2000/svg"
         fontFamily="TitilliumWeb-Regular, Titillium Web, sans-serif;" >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Tablet" transform="translate(-609.000000, -1776.000000)">
          <g id="keySymbolsTablet-updated" transform="translate(609.000000, 1776.000000)">
            <rect id="Rectangle" fillOpacity="0.35" fill="#415271" fillRule="nonzero" x="0" y="0" width="737"
                  height="259" rx="8"/>
            <g id="Report-created-on-23" transform="translate(20.000000, 201.000000)" fill="#8191AF" fontSize="13"
               >
              <text id="Report-created-on-23.01.2020">
                <tspan x="0" y="15">Report created on {getFormattedDate(new Date())}</tspan>
              </text>
            </g>
            <g id="KPIs-met" transform="translate(221.000000, 62.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">KPIs met</tspan>
              </text>
            </g>
            <g id="Group-3" transform="translate(20.000000, 90.000000)">
              <g id="Group-2">
                <circle id="Oval" stroke="#6AC0FF" cx="10.5" cy="10.5" r="10.5"/>
                <circle id="Oval" stroke="#4A5E7E" cx="10.5" cy="10.5" r="6.5"/>
              </g>
              <g id="day-ahead" transform="translate(33.000000, 2.000000)" fill="#8191AF" fontSize="13"
                 >
                <text>
                  <tspan x="0" y="15">day ahead</tspan>
                </text>
              </g>
            </g>
            <g id="Group" transform="translate(20.000000, 58.000000)">
              <circle id="Oval" stroke="#4A5E7E" cx="10.5" cy="10.5" r="10.5"/>
              <circle id="Oval" stroke="#6AC0FF" cx="10.5" cy="10.5" r="6.5"/>
            </g>
            <g id="-1d" transform="translate(20.000000, 125.000000)" fill="#689FDE" fontFamily="Avenir-Heavy, Avenir"
               fontSize="12" fontWeight="600">
              <text id="-1D">
                <tspan x="1" y="12">-1D</tspan>
              </text>
            </g>
            <g id="1-day-in-the-past" transform="translate(53.000000, 122.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">1 day in the past</tspan>
              </text>
            </g>
            <g id="intra-day" transform="translate(53.000000, 61.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">intra day</tspan>
              </text>
            </g>
            <g id="day-ahead" transform="translate(437.000000, 92.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">day ahead</tspan>
              </text>
            </g>
            <g id="weekly" transform="translate(437.000000, 123.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">weekly</tspan>
              </text>
            </g>
            <g id="intra-day" transform="translate(437.000000, 61.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">intra day</tspan>
              </text>
            </g>
            <g id="KPIs-breached" transform="translate(221.000000, 92.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">KPIs breached</tspan>
              </text>
            </g>
            <g id="Business-impact" transform="translate(221.000000, 123.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">Business impact</tspan>
              </text>
            </g>
            <g id="Group-10" transform="translate(188.000000, 59.000000)" fill="#6AC0FF" fillRule="nonzero">
              <circle id="Oval" cx="9" cy="9" r="9"/>
            </g>
            <g id="Group-22" transform="translate(188.000000, 90.000000)" fill="#B254CD" fillRule="nonzero">
              <g id="Group-13">
                <circle id="Oval" cx="9" cy="9" r="9"/>
              </g>
            </g>
            <g id="KPIs-met" transform="translate(576.000000, 62.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">KPIs met</tspan>
              </text>
            </g>
            <g id="KPIs-breached" transform="translate(576.000000, 93.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">KPIs breached</tspan>
              </text>
            </g>
            <g id="Business-impact" transform="translate(576.000000, 123.000000)" fill="#8191AF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">Business impact</tspan>
              </text>
            </g>
            <g id="Group-6" transform="translate(545.000000, 124.000000)" fillRule="nonzero">
              <g id="Group-4">
                <circle id="Oval" fill="#E36669" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
                <rect id="Rectangle" fill="#242E42" x="5" y="8" width="7" height="2"/>
              </g>
            </g>
            <g id="Group-6" transform="translate(188.000000, 124.000000)" fillRule="nonzero">
              <g id="Group-4">
                <circle id="Oval" fill="#E36669" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
                <rect id="Rectangle" fill="#242E42" x="5" y="8" width="7" height="2"/>
              </g>
            </g>
            <g id="Group-10" transform="translate(545.000000, 62.000000)" fillRule="nonzero">
              <circle id="Oval" fill="#6AC0FF" cx="9" cy="9" r="9"/>
              <polyline id="Fill-1" fill="#303D56"
                        points="13.3018269 4.15384615 7.44230769 10.0544211 4.69765385 7.49820243 2.76923077 9.39381377 7.44230769 13.8461538 15.2307692 6.04894737 13.3018269 4.15384615"/>
            </g>
            <g id="Group-22" transform="translate(545.000000, 93.000000)" fill="#B254CD" fillRule="nonzero">
              <g id="Group-13">
                <circle id="Oval" cx="9" cy="9" r="9"/>
              </g>
            </g>
            <g id="Circle" transform="translate(20.000000, 26.000000)" fill="#FFFFFF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">Circle</tspan>
              </text>
            </g>
            <g id="Details" transform="translate(405.000000, 28.000000)" fill="#FFFFFF" fontSize="13"
               >
              <text>
                <tspan x="0" y="15">Details</tspan>
              </text>
            </g>
            <g id="Time-2" transform="translate(404.000000, 60.000000)" fillRule="nonzero">
              <circle id="Oval" fill="#415271" cx="11" cy="11" r="11"/>
              <path
                d="M11.3928571,4.71428571 C8.13832201,4.71428571 5.5,7.35260772 5.5,10.6071429 C5.5,13.861678 8.13832201,16.5 11.3928571,16.5 C14.6473923,16.5 17.2857143,13.861678 17.2857143,10.6071429 C17.2857143,7.35260772 14.6473923,4.71428571 11.3928571,4.71428571 Z M11.3928571,15.6964286 C8.58212226,15.6964286 6.30357143,13.4178777 6.30357143,10.6071429 C6.30357143,7.79640797 8.58212226,5.51785714 11.3928571,5.51785714 C14.203592,5.51785714 16.4821429,7.79640797 16.4821429,10.6071429 C16.4821429,13.4178777 14.203592,15.6964286 11.3928571,15.6964286 Z"
                id="Shape" fill="#6AC0FF"/>
              <path
                d="M13.7936508,10.2396313 L11.6984127,10.2396313 L11.6984127,7.4516129 C11.6984127,7.24164289 11.5420677,7.07142857 11.3492063,7.07142857 C11.156345,7.07142857 11,7.24164289 11,7.4516129 L11,11 L13.7936508,11 C13.9865121,11 14.1428571,10.8297857 14.1428571,10.6198157 C14.1428571,10.4098457 13.9865121,10.2396313 13.7936508,10.2396313 L13.7936508,10.2396313 Z"
                id="Shape" fill="#6AC0FF"/>
            </g>
            <g id="Time-2"
               transform="translate(415.000000, 132.000000) rotate(90.000000) translate(-415.000000, -132.000000) translate(404.000000, 121.000000)"
               fillRule="nonzero">
              <circle id="Oval" fill="#415271" cx="11" cy="11" r="11"/>
              <path
                d="M4.83501456,10.3817394 C4.83501456,13.81479 7.61805197,16.5978275 11.0511026,16.5978275 C14.4841532,16.5978275 17.2671906,13.81479 17.2671906,10.3817394 C17.2671906,6.94868878 14.4841532,4.16565136 11.0511026,4.16565136 C7.61805197,4.16565136 4.83501456,6.94868878 4.83501456,10.3817394 Z M11.0511026,15.7501791 C8.08619524,15.7501791 5.68266293,13.3466468 5.68266293,10.3817394 C5.68266293,7.41683204 8.08619524,5.01329973 11.0511026,5.01329973 C14.01601,5.01329973 16.4195423,7.41683204 16.4195423,10.3817394 C16.4195423,11.8055381 15.8539404,13.1710218 14.8471627,14.1777995 C13.840385,15.1845772 12.4749013,15.7501791 11.0511026,15.7501791 Z"
                id="Shape" fill="#6AC0FF"/>
              <path
                d="M10.5312116,7.80488836 C10.3642194,7.64928337 10.1039913,7.65387479 9.94259226,7.81527386 C9.78119318,7.97667294 9.77660176,8.23690097 9.93220675,8.40389321 L11.8309391,10.3026256 L9.93220675,12.2013579 C9.77660176,12.3683502 9.78119318,12.6285782 9.94259226,12.7899773 C10.1039913,12.9513763 10.3642194,12.9559677 10.5312116,12.8003628 L13.0289488,10.3026256 L12.429944,9.70362071 L10.5312116,7.80488836 Z"
                id="Shape" fill="#6AC0FF"/>
            </g>
            <g id="Time-2" transform="translate(404.000000, 90.000000)" fillRule="nonzero">
              <circle id="Oval" fill="#415271" cx="11" cy="11" r="11"/>
              <path
                d="M11.0511026,4.16565136 C7.61805197,4.16565136 4.83501456,6.94868878 4.83501456,10.3817394 C4.83501456,13.81479 7.61805197,16.5978275 11.0511026,16.5978275 C14.4841532,16.5978275 17.2671906,13.81479 17.2671906,10.3817394 C17.2671906,6.94868878 14.4841532,4.16565136 11.0511026,4.16565136 L11.0511026,4.16565136 Z M11.0511026,15.7501791 C8.08619524,15.7501791 5.68266293,13.3466468 5.68266293,10.3817394 C5.68266293,7.41683204 8.08619524,5.01329973 11.0511026,5.01329973 C14.01601,5.01329973 16.4195423,7.41683204 16.4195423,10.3817394 C16.4195423,11.8055381 15.8539404,13.1710218 14.8471627,14.1777995 C13.840385,15.1845772 12.4749013,15.7501791 11.0511026,15.7501791 Z"
                id="Shape" fill="#6AC0FF"/>
              <path
                d="M10.5312116,7.80488836 C10.3642194,7.64928337 10.1039913,7.65387479 9.94259226,7.81527386 C9.78119318,7.97667294 9.77660176,8.23690097 9.93220675,8.40389321 L11.8309391,10.3026256 L9.93220675,12.2013579 C9.77660176,12.3683502 9.78119318,12.6285782 9.94259226,12.7899773 C10.1039913,12.9513763 10.3642194,12.9559677 10.5312116,12.8003628 L13.0289488,10.3026256 L12.429944,9.70362071 L10.5312116,7.80488836 Z"
                id="Shape" fill="#73ACFF"/>
            </g>
            <circle id="Oval" stroke="#232F46" strokeWidth="1.93050018" cx="554" cy="102" r="5"/>
            <text id="No-data-available" fontSize="13"
                  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="221" y="170">No data available</tspan>
            </text>
            <g id="Group-4" transform="translate(188.000000, 156.000000)" fill="#8191AF">
              <circle id="Oval" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
            </g>
            <text id="No-data-available" fontSize="13"
                  letterSpacing="0.722222222" fill="#8191AF">
              <tspan x="576" y="170">No data available</tspan>
            </text>
            <g id="Group-4" transform="translate(545.000000, 156.000000)" fill="#8191AF">
              <circle id="Oval" cx="8.55144011" cy="8.55144011" r="8.55144011"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
