import React from "react";
import {Element} from 'react-scroll';
import {usePromiseTracker} from "react-promise-tracker";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'react-day-picker/lib/style.css';
import '../styles/Dashboard.css';
import {Header} from "./Header";
import {NavigationBar} from "./NavigationBar";
import {Footer} from "./Footer";
import {Service} from "./Service";
import {Calendar} from "./Calendar";
import {Map} from "./SVGs/Map"
import {fetchSomething, getDjangoToken, ServicesResponse} from "../utils/api"
import {getDateKey, getInitDay, initNavBar, sortServices, emptyServicesResponse} from "../utils/utils";

interface DashboardProps {
  msalToken: string | null | undefined
  refreshMsalToken: (updateApiToken: typeof getDjangoToken, callback: (authToken: string) => void) => void
}

interface DashboardState {
  selectedDateString: string
  selectedDate: Date
  serviceData: ServicesResponse
  openDayPicker: boolean
  apiToken: string | null | undefined
}

const LoadingIndicator = () => {
  const {promiseInProgress} = usePromiseTracker();
  return (promiseInProgress ?
      <LinearProgress className="loading-indicator"/> : null
  );
};

export class Dashboard extends React.Component<DashboardProps, DashboardState> {
  constructor(props: DashboardProps) {
    super(props)
    let initDate = getInitDay()
    this.state = {
      selectedDate: initDate,
      selectedDateString: getDateKey(initDate),
      serviceData: emptyServicesResponse,
      openDayPicker: false,
      apiToken: undefined
    }
  }

  componentDidMount(): void {
    if (this.props.msalToken) {
      getDjangoToken(this.props.msalToken, this.updateApiToken, this.updateMsalToken)
    }
    this.setUpChangeDayOnPull()
  }

  updateServices = (response: ServicesResponse) => {
    if (response[getDateKey(this.state.selectedDate)]) {
      this.setState({
        serviceData: response,
        selectedDateString: getDateKey(this.state.selectedDate)
      })
    }
  }

  updateApiToken = (apiToken: string) => {
    //console.log("new api token: " + apiToken)
    this.setState({
      apiToken: apiToken,
    })
    fetchSomething(this.state.selectedDate, apiToken, this.updateServices);
  }

  updateMsalToken = () => {
    //console.log("refreshing token...")
    this.props.refreshMsalToken(getDjangoToken, this.updateApiToken)
  }

  setUpChangeDayOnPull = () => {
    let startY: number;
    const onTouchStart = (event: TouchEvent) => {
      startY = event.touches[0].pageY
    };
    const onTouchEnd = (event: TouchEvent) => {
      const y = event.changedTouches[0].pageY;
      if (document.scrollingElement !== undefined &&
        document.scrollingElement !== null &&
        document.scrollingElement.scrollTop < -50 && y > startY + 40) {
        // refresh
        if (getDateKey(this.state.selectedDate) !== getDateKey(getInitDay()) && this.state.apiToken) {
          this.setState({selectedDate: getInitDay()})
          fetchSomething(getInitDay(), this.state.apiToken, this.updateServices)
        }
      }
    };
    document.addEventListener("touchstart", onTouchStart, {passive: true});
    document.addEventListener("touchend", onTouchEnd, {passive: true});
  };

  getNavBarServices = () => {
    let {selectedDateString, serviceData} = this.state;
    const services = initNavBar.map((element) => (element.label));
    let result = initNavBar;
    result.forEach((service) => service.status = "")
    if (serviceData[selectedDateString] !== undefined && serviceData[selectedDateString].services.length > 0) {
      // for every service in navbar
      services.forEach((selectedService, index) => {
        let status = "";
        const selectedServiceData = serviceData[selectedDateString].services.filter(data => data.label.toLowerCase() === selectedService);
        if (selectedServiceData.length > 0) {
          //check outer circle value
          const outerReports = selectedServiceData.map(data => data.coloring.reports[0].outer)
          if (outerReports.length > 0) {
            status = outerReports[0]
          }
          //check inner circle if exists
          const innerReports = selectedServiceData.map(data => data.coloring.reports[0].inner)
          if (innerReports.length > 0) {
            let innerStatus = innerReports[0]
            if (innerStatus === "red" || status === "red") {
              status = "red"
            } else if (innerStatus === "purple" || status === "purple") {
              status = "purple"
            }
          }
        }
        result[index] = {label: selectedService, status: status}
      })
    }
    return result
  }

  handleOpenDayPicker = () => {
    this.setState({openDayPicker: true})
  };

  handleCloseDayPicker = () => {
    this.setState({openDayPicker: false})
  };

  // change day through the calendar
  handleChangeDayPicker = (newDate: Date | undefined) => {
    this.setState({openDayPicker: false})
    if (newDate !== undefined) {
      this.setState({selectedDate: newDate})
      if (this.state.apiToken) {
        fetchSomething(newDate, this.state.apiToken, this.updateServices)
      }
    }
  };

  // change day through tap on little circles in visualisation
  changeDay = (offset: number) => {
    //if (getDateKey(this.state.selectedDate) === this.state.selectedDateString) {
      let newDate = new Date(this.state.selectedDate)
      newDate.setDate(newDate.getDate() + offset)
      this.setState({selectedDate: newDate})
      if (this.state.apiToken) {
        fetchSomething(newDate, this.state.apiToken, this.updateServices)
      }
    //}
  }

  render() {
    const {serviceData, selectedDate, selectedDateString, openDayPicker} = this.state
    let mapColor = serviceData[selectedDateString] !== undefined ? serviceData[selectedDateString].mapColoring : "gray"
    return (
      <div>

        <div>
          <div className="sticky-header">
            <div className="pull-animation">
              <CircularProgress/>
            </div>
            <Header processDate={selectedDate} callback={this.handleOpenDayPicker}/>
            {openDayPicker ? <Calendar closeCallback={this.handleCloseDayPicker}
                                       changeDateCallback={this.handleChangeDayPicker}/> : null}
            <NavigationBar
              landscape={false}
              services={this.getNavBarServices()}
            />
            <LoadingIndicator/>
          </div>
          <NavigationBar
            landscape={true}
            services={this.getNavBarServices()}
          />
          <Element name="container" className="scrollable-view" id="serviceContainer">
            <Element className="map" name="map">
              <Map status={mapColor}/>
            </Element>

            <div className="ServiceGrid">
              {
                serviceData[selectedDateString]?.services.sort(sortServices).map((service, index) => (
                    service.coloring.names.outer !== null
                      ?
                      <Element key={service.label.toLowerCase() + index} name={service.label.toLowerCase()}>
                        <Service serviceReport={service} changeDay={this.changeDay}/>
                      </Element>
                      : null
                  )
                )
              }
            </div>
            <Footer/>
          </Element>
        </div>
      </div>
    )
  }
}
