import React, {useState} from "react"
import '../styles/Service.css';
import {Circle} from './SVGs/ServiceCircle'
import {KPIData, ServiceKPIReport, ServiceReport} from "../utils/api";
import {formatTimeFrame, getServiceFullName} from "../utils/utils"
import {ReactComponent as Red} from "../assets/smallBusinessImpact.svg"
import {ReactComponent as Purple} from "../assets/smallKPIBreach.svg"
import {ReactComponent as Blue} from "../assets/smallCheck.svg"
import {DayIcon} from "./SVGs/DayIcon";

export interface ServiceProps {
  serviceReport: ServiceReport
  changeDay: (offset: number) => void
}

export interface DayReport {
  inner?: string,
  outer: string
}

interface DayOverviewBarData {
  [key: string]: string
}

function SmallStatusIcon(props: { status: string }) {
  switch (props.status.toLowerCase()) {
    case "blue":
      return <Blue className="status-icon"/>;
    case "purple":
      return <Purple className="status-icon"/>;
    case "red":
      return <Red className="status-icon"/>;
    default:
      return null
  }
}

function DayOverviewBar(props: { dayOverview: DayOverviewBarData }) {
  let {dayOverview} = props;
  return (
    <div className="day-overview__row">
      {dayOverview["noData"] !== undefined ?
        <div className="day-overview__row__element">
          <div>{dayOverview["noData"]}</div>
        </div> : null}
      {dayOverview["Performance"] !== undefined && dayOverview["Performance"] !== "gray" ?
        <div className="day-overview__row__element">
          <div>Performance</div>
          <SmallStatusIcon status={dayOverview["Performance"]}/>
        </div> : null}
      {dayOverview["Quality"] !== undefined && dayOverview["Quality"] !== "gray" ?
        <div className="day-overview__row__element">
          <div>Quality</div>
          <SmallStatusIcon status={dayOverview["Quality"]}/>
        </div> : null}
      {dayOverview["Availability"] !== undefined && dayOverview["Availability"] !== "gray" ?
        <div className="day-overview__row__element">
          <div>Availability</div>
          <SmallStatusIcon status={dayOverview["Availability"]}/>
        </div> : null}
    </div>
  )
}

function KPIDescription(props: { kpis: KPIData[] }) {

  const {kpis} = props;
  return (
    <>
      {kpis.map((kpi) => {
        return (
          <div key={kpi.label} className="kpi-container">
            <div className="kpi-container__title"> {kpi.label} </div>
            {kpi.message !== null && kpi.message.length !== 0
              ? <div className="kpi-container__desc">
                <div className="kpi-container__message">
                  {kpi.message}
                </div>
              </div>
              : null}
            <div className="kpi-container__desc">
              Responsible: {kpi.owner}
            </div>
            <div className="kpi-container__desc">
              <div className="kpi-container__title__column-1">
                <div className="kpi-container__title__column-text">
                  Type: {kpi.kpiType}
                </div>
                {/*<div className="kpi-container__title__column-text">*/}
                {/*  Responsible: {kpi.owner}*/}
                {/*</div>*/}
                <div
                  className={kpi.impact === "Business Impact" ? "kpi-container__title__column-text text--red" : "kpi-container__title__column-text"}>
                  {kpi.impact}
                </div>
              </div>
              <div className="kpi-container__title__column-2">
                <div className="kpi-container__title__column-text">
                  Threshold: {kpi.threshold}
                </div>
                {kpi.difference !== null && kpi.difference.length !== 0 ? <div className="kpi-container__title__column-text">
                  Difference: {kpi.difference}
                </div> : null}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export function ServiceDayDescription(props: {
  dayLabel: string,
  kpis: KPIData[],
  dayOverview: DayOverviewBarData
}) {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open)
  };

  const getDayStatus = () => {
    const statuses = Object.keys(props.dayOverview).map(key => props.dayOverview[key]);
    if (statuses.filter(status => status === "red").length > 0) {
      return "red"
    } else if (statuses.filter(status => status === "purple").length > 0) {
      return "purple"
    } else if (statuses.filter(status => status === "blue").length > 0) {
      return "blue"
    }
    return "gray"
  };

  return (
    <div className="day__container">
      <div className="expand-icon" onClick={handleClick}>
        <DayIcon status={getDayStatus()} dayLabel={props.dayLabel}/>
      </div>
      <div className="day-desc__container">
        <div className="day-overview__title"> {props.dayLabel.toUpperCase()} </div>
        <DayOverviewBar dayOverview={props.dayOverview}/>
        {open ? <KPIDescription kpis={props.kpis}/> : null}
      </div>
    </div>
  )
}

export function ServiceDescription(
  props: {
    label: string,
    kpis: KPIData[],
    daysOverview: ServiceKPIReport
  }) {

  let {label, kpis, daysOverview} = props;

  const sortKeys = (a: string, b: string) => {
    const dayKeys = ["dayAhead", "intraDay", "weekAhead", "yearAhead"];
    return dayKeys.indexOf(a) - dayKeys.indexOf(b)
  };

  return (
    <div className="service-container__description">
      <div className="service-title">
        {getServiceFullName(label)}
      </div>
      <div className="divider"/>
      {Object.keys(daysOverview).sort(sortKeys).map(key => (
        <div key={key}>
          <ServiceDayDescription
            dayLabel={formatTimeFrame(key)}
            kpis={kpis.filter(kpi => kpi.timeFrame === formatTimeFrame(key))}
            dayOverview={daysOverview[key] ?? []}/>
          <div className="divider"/>
        </div>
      ))}
    </div>
  )
}

export class Service extends React.Component<ServiceProps> {

  dayOverview = () => {
    const {worstOfSameKpis, names} = this.props.serviceReport.coloring
    if (worstOfSameKpis !== null) {
      return worstOfSameKpis
    } else {
      const emptyDayOverview: ServiceKPIReport = {}
      const outerDayOverview: DayOverviewBarData = {}
      outerDayOverview["noData"] = "No data available"
      emptyDayOverview[names.outer] = outerDayOverview
      if (names.inner) {
        emptyDayOverview[names.inner] = outerDayOverview
      }
      return emptyDayOverview
    }
  }

  render() {
    let {changeDay, serviceReport: {coloring: {reports}, date, kpis, label}} = this.props;
    return (
      <div className="service-container">
        <div className="service-container__circle">
          <Circle label={label} date={new Date(date)} reports={reports} changeDay={changeDay}/>
        </div>
        <ServiceDescription label={label}
                            kpis={kpis}
                            daysOverview={this.dayOverview()}
        />
      </div>
    )
  }
}

